import React from "react";

class Footer extends React.Component {
	render() {
		return (
			<footer>
				<div className="sponsors">
					<a href="https://hackingforsoju.team/" target="_blank"><img src="/hfs.png" /></a>
					<a href="https://kodsport.se/" target="_blank"><img src="/ks.png" /></a>
				</div>
				{/*<span className="power">forevr CTF platform</span>*/}
			</footer>
		);
	}
}

export default Footer;
