import React from "react";
import { NavLink } from "react-router-dom";

const MaxTeamWidth = 200;

class Placement extends React.Component {
	render() {
		let classes = [];
		if (this.props.score === 0) classes.push("noob");
		else if (this.props.eligible) {
			let eligibleRank = app.state.teams.filter(team => team.prize_eligible).findIndex(team => team.id === this.props.id) + 1;
			if (eligibleRank <= 3) {
				classes.push("prize");
			}
		} else if (this.props.rank === 1) classes.push("first");
		else if (this.props.rank === 2) classes.push("second");
		else if (this.props.rank === 3) classes.push("third");
		if (this.props.isSelf) classes.push("self");
		
		let width = app.calculateTextSize(this.props.name);
		let scale = Math.min(MaxTeamWidth / width, 1);
		
		let solvedAll = app.state.challs.length !== 0 && Object.keys(this.props.solves).length === app.state.challs.length;
		
		return <tr className={classes.join(" ")}>
			<td><span className="rank">{this.props.rank}</span></td>
			<td><NavLink
				className="team"
				to={"/teams/" + this.props.id}
				style={{transform: `scale(${scale})`}}
			>{this.props.name}</NavLink></td>
			<td>{this.props.eligible && <span className="eligible" title="Prize eligible"></span>}</td>
			<td title={this.props.score + " points"}>
				{solvedAll ? "Bingo" : this.props.score}
			</td>
			{app.state.challs.map(chall => {
				let solved = chall.id in this.props.solves;
				return <td className={"chall" + (solved ? " solved" : "")} key={chall.id}></td>;
			})}
		</tr>;
	}
}

class ScoreboardPage extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			isLoading: true
		};
	}
	
	componentDidMount() {
		if (app.net && app.net.teamsState === "loaded") {
			setTimeout(() => {
				this.setState({
					isLoading: false
				});
			}, 10);
		} else {
			this.setState({
				isLoading: false
			});
		}
	}
	
	render() {
		if (this.state.isLoading) {
			return <main>
				<h1>Scoreboard</h1>
				<div className="loading">
					<span className="title">Did you know?</span>
					<span className="body">The scoreboard is actually already loaded locally. We're just waiting for React to slowly render the page. We regret choosing React, but it's too late now. Personally I don't think the React paradigm is a step in the right direction, it puts way too much of a burden on the already slow browser. Hopefully the future of the web is brighter than this.</span>
				</div>
			</main>;
		}
		
		if (!app.net || app.net.teamsState !== "loaded") {
			if (app.net) {
				app.net.getTeams();
			}
			
			return <main>
				<h1>Scoreboard</h1>
				<p>Loading</p>
			</main>;
		}
		
		return <main className="scoreboard-page">
			<h1>Scoreboard</h1>
			<table className="scoreboard" cellSpacing="0" cellPadding="0">
				<thead>
					<tr>
						<th>#</th>
						<th>Team</th>
						<th></th>
						<th>Score</th>
						{app.state.challs.map(chall => {
							return <th className="chall-name" key={chall.id}><span>{chall.title || "???"}</span></th>
						})}
					</tr>
				</thead>
				<tbody>
					{this.props.teams.filter(team => team.score > 0).map((team, i) => {
						return <Placement
							id={team.id}
							rank={i + 1}
							name={team.name}
							score={team.score}
							solves={team.solves}
							eligible={team.prize_eligible}
							isSelf={this.props.team && team.id === this.props.team.id}
							key={team.id}
						/>;
					})}
				</tbody>
			</table>
		</main>;
	}
}

export default ScoreboardPage;
