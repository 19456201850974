import React from "react";

class HomeClock extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			time: Date.now(),
			start: app.startTime,
			end: app.endTime
		};
	}

	componentDidMount() {
		this.interval = setInterval(() => this.tick(), 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	tick() {
		this.setState({
			time: Date.now(),
			start: app.startTime,
			end: app.endTime
		});
	}

	render() {
		const now = this.state.time;
		const start = this.state.start;
		const end = this.state.end;

		let seconds, minutes, hours, days;
		let status;
		let progress;
		let you;

		function zeroPad(value, length) {
			return String(value).padStart(length, "0");
		}

		function setTime(time) {
			time = Math.floor(time / 1000);
			seconds = zeroPad(time % 60, 2);

			time = Math.floor(time / 60);
			minutes = zeroPad(time % 60, 2);

			time = Math.floor(time / 60);
			hours = zeroPad(time % 24, 2);

			time = Math.floor(time / 24);
			days = zeroPad(time, 2);
		}

		function getDate(time) {
			return new Date(time).toString().split(" ").slice(1, 5).join(" ").slice(0, -3);
		}
		
		if (!start || !end) {
			status = "Loading...";
			seconds = "--";
			minutes = "--";
			hours = "--";
			days = "--";
			progress = 0;
			you = {left: "-24px"};
		} else if (now < start) {
			setTime(start - now);

			status = "until CTF begins";

			progress = 0;
			you = {left: "-24px"};
		} else if (now < end) {
			setTime(end - now);

			status = "until CTF ends";

			progress = (now - start) / (end - start);
			you = {left: progress * 100 + "%"};
		} else {
			seconds = "--";
			minutes = "--";
			hours = "--";
			days = "--";

			status = "CTF has ended";

			progress = 1;
			you = {right: "-24px"};
		}

		return (
			<div className="home-clock">
				<div className="time">
					<div>
						<span className="value">{days}</span>
						<span className="label">Days</span>
					</div>
					<div>
						<span className="value">{hours}</span>
						<span className="label">Hours</span>
					</div>
					<div>
						<span className="value">{minutes}</span>
						<span className="label">Minutes</span>
					</div>
					<div>
						<span className="value">{seconds}</span>
						<span className="label">Seconds</span>
					</div>
				</div>
				<span className="status">{status}</span>
				<div className="timeline">
					<span className="start">{getDate(start)}</span>
					<div className="progress" style={{width: progress * 100 + "%"}}></div>
					<div className="you" style={you}></div>
					<span className="end">{getDate(end)}</span>
				</div>
			</div>
		);
	}
}

class HomePage extends React.Component {
	render() {
		return <main>
			<h1>watevrCTF 2019</h1>
			<HomeClock />
			<div className="columns">
				<div className="column">
					<h2>Description</h2>
					<p>Welcome to watevrCTF 2019! watevrCTF is a security capture the flag competition for everyone, whether you are just starting out or have already played a fair amount of CTF competitions before. Our aim is to provide quality challenges that do not require unreasonable guessing to solve.</p>
					<p>A CTF competition is a cybersecurity competition where you get to solve lots of computer-related puzzles and challenges. Each challenge contains a string called a flag, which you must find and submit to this website to receive points.</p>
					<p>The event takes place online during a 48h period between December 13th 19:00 UTC and December 15th 19:00 UTC.</p>
					<p>The flag format is <code>watevr{"{"}example_flag{"}"}</code> unless specified otherwise.</p>
					<p>This CTF has dynamic scoring. The score function is <code>round(10 + 490 / (1 + (solves - 1) / 12))</code> for all challenges.</p>
					<h2>Schedule</h2>
					<ul>
						<li>T+0h - CTF start</li>
						<li>T+1h - 2 crypto released</li>
						<li>T+4h - reverse released</li>
						<li>T+8h - reverse released</li>
						<li>T+12h - misc released</li>
						<li>T+16h - web released</li>
						<li>T+20h - reverse released</li>
						<li>T+24h - misc released</li>
						<li>T+48h - CTF end</li>
					</ul>
					<p>The schedule might change</p>
					<h2>Chat with us</h2>
					<center><a href="https://discordapp.com/invite/We5GxvC" target="_blank">On Discord</a></center>
				</div>
				<div className="divider"></div>
				<div className="column">
					<h2>Rules</h2>
					<ul>
						<li>Teams can be of any size.</li>
						<li>Discussing challenge solutions or hints with people outside your team during the competition is strictly forbidden. This also applies to the Discord server.</li>
						<li>Being a member of multiple teams is forbidden.</li>
						<li>Attacking the CTF platform is forbidden. This includes brute-forcing flags.</li>
						<li>Do not generate excessive amounts of traffic by for example URL directory fuzzing or similar means. It will not get you flags.</li>
						<li>Disrupting other teams' ability to solve challenges is forbidden.</li>
						<li>Creating multiple accounts or teams for any reason is forbidden. In cases where you were locked out of your account or accidentally created multiple accounts please contact us through discord.</li>
					</ul>
					<p>Breaking any of these rules may result in a suitable punishment. If you find that someone is breaking these rules please report it to us through discord.</p>
					<h2>Prizes</h2>
					<ul>
						<li>1st place: a Raspberry Pi 4 2GB RAM + an RTL-SDR dongle + a reasonably priced domain name</li>
						<li>2nd place: a Raspberry Pi 4 2GB RAM + an RTL-SDR dongle</li>
						<li>3rd place: a Raspberry Pi 4 2GB RAM</li>
					</ul>
					<p>To be eligible to receive prizes you must meet all of the following criteria:</p>
					<ul>
						<li>You are not affiliated with any of our sponsors</li>
						<li>At least 70% of your teams members are located in Sweden</li>
						<li>Your team has less than or equal to 7 members</li>
					</ul>
				</div>
			</div>
			<div className="sponsors">
				<h2>Sponsors</h2>
				<p>We want to thank our sponsors for making this competition possible!</p>
				<p>The Swedish CTF team <a href="https://hackingforsoju.team/" target="_blank">Hacking For Soju</a> has generously covered the server costs, and the Swedish organization <a href="https://kodsport.se/" target="_blank">Kodsport</a> which works with programming interested youth is providing the competition prizes.</p>
				<div className="images">
					<a href="https://hackingforsoju.team/" target="_blank"><img src="hfs.png" /></a>
					<a href="https://kodsport.se/" target="_blank"><img src="ks.png" height="120" /></a>
				</div>
			</div>
		</main>;
	}
}

export default HomePage;
