import React from "react";
import { Redirect, Link } from "react-router-dom";

import history from "./history";

class ProfilePage extends React.Component {
	constructor(props) {
		super(props);
		
		this.copyJoinToken = this.copyJoinToken.bind(this);
	}
	
	signOut() {
		app.net.logout();
		history.push("/");
	}
	
	joinTeam(event) {
		event.preventDefault();
		let token = event.target.children.token.value;
		
		app.net.joinTeam(token);
		return false;
	}
	
	createTeam(event) {
		event.preventDefault();
		let name = event.target.children.name.value;
		
		app.net.createTeam(name);
		return false;
	}
	
	updateTeam(event) {
		event.preventDefault();
		
		let affiliation = document.getElementById("team-affiliation").value;
		let website = document.getElementById("team-website").value;
		let eligible = document.getElementById("team-eligible").checked;
		
		app.net.updateTeam(affiliation, website, eligible);
		return false;
	}
	
	copyJoinToken() {
		let input = document.createElement("input");
		input.value = this.props.team.join_token;
		document.body.appendChild(input);
		input.select();
		document.execCommand("copy");
		document.body.removeChild(input);
		
		app.alert("success", "Copied join token", "Send it to your teammates!");
		
		event.preventDefault();
		return false;
	}
	
	link(platform) {
		let url = app.net.oauth[platform];
		let win = window.open(url, platform, "width=600,height=800");
		let interval = setInterval(() => {
			if (win.closed) {
				clearInterval(interval);
				return;
			}
			
			let code;
			try {
				if (win.location.pathname !== "/callback/") {
					return;
				}
				code = decodeURIComponent(win.location.search.slice(1).split("&").map(a => a.split("=")).filter(a => a[0] === "code")[0][1]);
			} catch(e) {
				return;
			}
			
			app.net.link(platform, code).then(data => {
				win.close();
			});
			clearInterval(interval);
		}, 10);
	}
	
	render() {
		return <main>
			<h1>Profile</h1>
			{this.props.user ? (<>
				{this.props.team ? (<>
					<form className="team-edit" onSubmit={this.updateTeam}>
						<h2><Link to={"/teams/" + this.props.team.id}>{this.props.team.name}</Link></h2>
						<label htmlFor="team-affiliation">
							Affiliation
							<input id="team-affiliation" defaultValue={this.props.team.affiliation} type="text" placeholder="None" />
						</label>
						<label htmlFor="team-website">
							Website
							<input id="team-website" defaultValue={this.props.team.website} type="text" placeholder="None" />
						</label>
						<input id="team-eligible" type="checkbox" defaultChecked={this.props.team.prize_eligible} />
						<label htmlFor="team-eligible" title="Check this box if your team is eligible to receive prizes. The requirements are listed under the rules.">Prize eligible (?)</label>
						<input type="submit" value="Update" />
						<button className="join-token" onClick={this.copyJoinToken}>Copy join token</button>
					</form>
				</>) : (<>
					<div className="team-edit">
						<form onSubmit={this.joinTeam}>
							<h2>Join a team</h2>
							<input type="text" name="token" required placeholder="Join token" />
							<input type="submit" value="Join" />
						</form>
						<form onSubmit={this.createTeam}>
							<h2>Create a team</h2>
							<input type="text" name="name" required placeholder="Team name" />
							<input type="submit" value="Create" />
						</form>
					</div>
				</>)}
				<div className="account">
					<h2>Link account</h2>
					<button className="sso discord" onClick={e => this.link("discord")} disabled={this.props.user.discord_connected}></button>
					<button className="sso github" onClick={e => this.link("github")} disabled={this.props.user.github_connected}></button>
					<button className="sso google" onClick={e => this.link("google")} disabled={this.props.user.google_connected}></button>
					
					<button className="signout" onClick={this.signOut}>Sign Out</button>
				</div>
			</>) : (
				<h2>Not logged in</h2>
			)}
		</main>;
	}
}

export default ProfilePage;
