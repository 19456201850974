import NavBar from "./navbar";
import Footer from "./footer";
import HomePage from "./home";
import ChallengePage from "./challenges";
import ScoreboardPage from "./scoreboard";
import TeamPage from "./team";
import SignInPage from "./signin";
import SignUpPage from "./signup";
import ProfilePage from "./profile";
import NotFoundPage from "./404";

import React from "react";
import { Switch, Route } from "react-router-dom";

import Net from "./net";

class App extends React.Component {
	constructor(props) {
		super(props);
		
		this.net = new Net();
		
		this.startTime = null;
		this.endTime = null;
		
		this.state = {
			challs: [],
			user: null,
			teams: [],
			notifs: [],
			toasts: [],
			connected: false
		};
	}
	
	updateChalls(challs) {
		this.state.challs.forEach(chall => {
			if (challs.some(a => a.id === chall.id)) return;
			
			challs.push(chall);
		});
		
		this.setState({
			challs: challs
		});
	}
	
	calculateScore(chall) {
		let solves = chall.solves;
		/*let max = 500;
		let magicDust = 50;
		
		return Math.round((max - 1) * Math.exp(solves / -magicDust) + 1);*/
		
		return Math.min(Math.round(10 + 490 / (1 + (solves - 1) / 12)), 500);
	}
	
	calculateTextSize(text) {
		let sizeElem = document.getElementById("hidden");
		sizeElem.textContent = text;
		return sizeElem.clientWidth;
	}
	
	sortTeams() {
		let teams = this.state.teams;
		for (let i=0; i<teams.length; i++) {
			teams[i].score = 0;
			Object.keys(teams[i].solves).forEach(solve => {
				let chall = this.state.challs.find(c => c.id === parseInt(solve));
				teams[i].score += this.calculateScore(chall);
			});
		}
		teams.sort((a, b) => {
			let scoreDiff = b.score - a.score;
			if (scoreDiff) return scoreDiff;
			
			return a.tiebreaker - b.tiebreaker;
		});
		
		this.setState({
			teams: teams
		});
	}
	
	alert(type, title, body) {
		let toast = {
			type: type,
			title: title,
			body: body,
			id: Date.now()
		};
		let toasts = this.state.toasts;
		toasts.push(toast);
		this.setState({
			toasts: toasts
		});
		setTimeout(() => {
			let toasts = this.state.toasts;
			toasts.splice(toasts.indexOf(toast), 1);
			this.setState({
				toasts: toasts
			});
		}, 8000);
	}
	
	render() {
		return (
			<>
				<NavBar user={this.state.user} />
				<Switch>
					<Route exact path="/" exact component={HomePage} />
					<Route path="/challenges" render={props => <ChallengePage
						{...props}
						challs={this.state.challs}
					/>} />
					<Route path="/scoreboard" exact render={props => <ScoreboardPage
						{...props}
						teams={this.state.teams}
						team={this.state.team}
					/>} />
					<Route path="/teams/:id" exact component={TeamPage} />
					<Route path="/signin" render={props => <SignInPage
						{...props}
						user={this.state.user}
					/>} />
					<Route path="/signup" exact component={SignUpPage} />
					<Route path="/profile" exact render={props => <ProfilePage
						{...props}
						user={this.state.user}
						team={this.state.team}
					/>} />
					<Route component={NotFoundPage} />
				</Switch>
				<Footer />
				<div className="toasts">
					{this.state.toasts.map(toast => {
						return <div className={toast.type} key={toast.id}>
							<div className="title">{toast.title}</div>
							{toast.body && <div className="body">{toast.body}</div>}
						</div>;
					})}
				</div>
			</>
		);
	}
}

export default App;
