import debug from "./debug.js";

/*

load:
	if not logged in:
		* send all challenges
	if logged in:
		* send all challenged + unlocked challenges

*/

export default class Net {
	constructor() {
		this.oauth = {};

		this.connDelay = 1000;
		this.disconnected = false;

		this.teamsState = "none";

		this.connected();
		// this.startWs();
	}

	request(endpoint, data, method) {
		if (data) {
			let csrfToken = decodeURIComponent(document.cookie.split("; ").map(a => a.split("=")).filter(a => a[0] === "csrftoken")[0][1]);
			let formData = new FormData();
			for (let arg in data) {
				formData.append(arg, data[arg]);
			}
			return fetch(endpoint, {
				method: method || "POST",
				body: formData,
				headers: {
					"X-CSRFToken": csrfToken
				}
			}).then(res => res.json()).catch(err => {
				console.log(err);
			});
		} else {
			return fetch(endpoint).then(res => res.json());
		}
	}

	notifRequest() {
		if (app.state.team && ("Notification" in window) && Notification.permission === "default") {
			Notification.requestPermission();
		}
	}

	connected() {
		this.request("/api/watsup").then(data => {
			console.log(data);

			this.oauth.discord = data.discord_login;
			this.oauth.github = data.github_login;
			this.oauth.google = data.google_login;

			app.startTime = data.start_time * 1000;
			app.endTime = data.end_time * 1000;

			app.setState({
				challs: data.challenges,
				user: data.user,
				team: data.team,
				notifs: data.announcements
			});
			app.setState({
				connected: true
			});
			app.sortTeams();

			this.notifRequest();
		});
	}

	startWs() {
		this.ws = new WebSocket("ws://" + location.hostname + "/ws/main");

		this.ws.addEventListener("open", () => {
			debug("WebSocket opened");
			app.setState({
				connected: true
			});
			if (this.disconnected) {
				this.disconnected = false;
				this.connDelay = 1000;
				app.alert("success", "WebSocket opened");
			}
			this.connected();
		});

		this.ws.addEventListener("message", event => {
			let msg = JSON.parse(event.data);
			this.onMessage(msg);
		});

		this.ws.addEventListener("close", () => {
			debug("WebSocket closed");
			app.setState({
				connected: false
			});
			this.disconnected = true;
			app.alert("error", "WebSocket closed", "Reconnecting in " + this.connDelay / 1000 + " seconds");
			setTimeout(() => {
				this.startWs();
			}, this.connDelay);
			this.connDelay *= 2;
		});

		this.ws.addEventListener("error", function () {
			app.alert("error", "WebSocket error");
		});
	}

	onMessage(msg) {
		console.log(msg);

		switch (msg.type) {
			case "error":
				app.alert("error", "An error occurred", "Code: " + msg.code);
				break;
			case "login":
				this.notifRequest();
				break;
			case "update_team":
				let teams = app.state.teams;
				let index = teams.findIndex(team => team.id === msg.team.id);
				teams[index] = msg.team;
				app.sortTeams();

				if (app.state.team && app.state.team.id === msg.team.id) {
					app.setState({
						team: msg.team
					});
				}
				break;
			case "flag":
				let time = msg.team_tiebreaker; // FIXME
				let solve = {
					time: time,
					user: msg.member,
					tries: msg.tries
				};
				if (app.state.teams.length) {
					let team = app.state.teams.find(team => team.id === msg.team_id);
					team.solves[msg.challenge_id] = solve;
					team.tiebreaker = msg.team_tiebreaker;
					app.sortTeams();
				}
				if (app.state.team && app.state.team.id === msg.team_id) {
					app.state.team.solves[msg.challenge_id] = solve;
					app.setState({
						team: app.state.team
					});
				}
				let chall = app.state.challs.find(c => c.id === msg.challenge_id);
				if (chall.top_solved_teams.length < 3) {
					chall.top_solved_teams.push({
						id: msg.team_id,
						name: msg.team_name,
						time: time,
						tries: msg.tries
					});
				}
				if (msg.rating !== null) {
					chall.unsolved_rating_sum -= msg.rating;
					chall.unsolved_rating_count--;
					chall.solved_rating_sum += msg.rating;
					chall.solved_rating_count++;
				}
				chall.solves++;
				app.setState({
					challs: app.state.challs
				});
				break;
			case "announcement":
				let notifs = app.state.notifs;
				notifs.push(msg.announcement);
				app.setState({
					notifs: notifs
				});
				if (("Notification" in window) && Notification.permission === "granted") {
					let notif = new Notification(msg.announcement.title, {
						body: msg.announcement.description
					});
					notif.addEventListener("click", () => {
						window.focus();
					});
				}
				break;
			case "rating": {
				let chall = app.state.challs.find(c => c.id === msg.challenge_id);
				if (app.state.team && app.state.team.id === msg.team_id) {
					chall.team_rating = msg.rating;
					app.setState({
						challs: app.state.challs
					});
				}

				// :(
				if (msg.last_rating) {
					if (msg.solved) {
						chall.solved_rating_sum -= msg.last_rating;
					} else {
						chall.unsolved_rating_sum -= msg.last_rating;
					}
				} else {
					if (msg.solved) {
						chall.solved_rating_count++;
					} else {
						chall.unsolved_rating_count++;
					}
				}

				if (msg.solved) {
					chall.solved_rating_sum += msg.rating;
				} else {
					chall.unsolved_rating_sum += msg.rating;
				}

				app.setState({
					challs: app.state.challs
				});
				break;
			}
			case "challenge":
				for (let i = 0; i < msg.challenges.length; i++) {
					let index = app.state.challs.findIndex(a => a.id === msg.challenges[i].id);
					if (index === -1) {
						app.state.challs.push(msg.challenges[i]);
					} else {
						app.state.challs[index] = msg.challenges[i];
					}
				}
				app.setState({
					challs: app.state.challs
				});
				break;
		}
	}

	wsLogin() {
		let session = decodeURIComponent(document.cookie.split("; ").map(a => a.split("=")).filter(a => a[0] === "sessionid")[0][1]);
		this.ws.send(JSON.stringify({
			type: "login",
			sessionid: session
		}));
	}

	wsLogout() {
		this.ws.send(JSON.stringify({
			type: "logout"
		}));
	}

	login(service, code) {
		return this.request("/api/login", {
			service: service,
			code: code
		}).then(data => {
			if (data.success) {
				this.wsLogin();

				app.setState({
					user: data.user,
					team: data.team
				});
				app.updateChalls(data.challenges);
				app.alert("success", "Signed in");
			} else if (!data.registration_required) {
				for (let group in data.errors) {
					data.errors[group].forEach(error => {
						let message = (group !== "__all__" ? group + ": " : "") + error.message;
						app.alert("error", "Signin failed", message);
					});
				}
			}

			return data;
		});
	}

	register(username) {
		return this.request("/api/register", {
			username: username
		}).then(data => {
			if (data.success) {
				this.wsLogin();
				app.alert("success", "Signed up");
			} else {
				for (let group in data.errors) {
					data.errors[group].forEach(error => {
						let message = (group !== "__all__" ? group + ": " : "") + error.message;
						app.alert("error", "Signup failed", message);
					});
				}
			}

			return data;
		});
	}

	logout() {
		this.request("/api/logout", {}).then(() => {
			this.wsLogout();
			this.connected();
			app.alert("success", "Signed out");
		});
	}

	link(service, code) {
		return this.request("/api/login", {
			service: service,
			code: code
		}).then(data => {
			if (data.linked) {
				let user = app.state.user;
				user[service + "_connected"] = true;
				app.setState({
					user: user
				});
				app.alert("success", "Linked account");
			} else {
				for (let group in data.errors) {
					data.errors[group].forEach(error => {
						let message = (group !== "__all__" ? group + ": " : "") + error.message;
						app.alert("error", "Failed to link account", message);
					});
				}
			}

			return data;
		});
	}

	createTeam(name) {
		return this.request("/api/team", {
			name: name
		}).then(data => {
			if (data.success) {
				app.setState({
					team: data.team
				});
				app.alert("success", "Created team");
			} else {
				for (let group in data.errors) {
					data.errors[group].forEach(error => {
						let message = (group !== "__all__" ? group + ": " : "") + error.message;
						app.alert("error", "Failed to create team", message);
					});
				}
			}

			return data;
		});
	}

	joinTeam(token) {
		return this.request("/api/join_team", {
			join_token: token
		}).then(data => {
			if (data.success) {
				app.setState({
					team: data.team
				});
				app.updateChalls(data.challenges);
				app.alert("success", "Joined team");
			} else {
				for (let group in data.errors) {
					data.errors[group].forEach(error => {
						let message = (group !== "__all__" ? group + ": " : "") + error.message;
						app.alert("error", "Failed to join team", message);
					});
				}
			}

			return data;
		});
	}

	updateTeam(affiliation, website, eligible) {
		return this.request("/api/team", {
			affiliation: affiliation,
			website: website,
			prize_eligible: eligible
		}, "PATCH").then(data => {
			if (data.success) {
				let team = app.state.team;
				team.affiliation = affiliation;
				team.website = website;
				app.setState({
					team: team
				});
				app.alert("success", "Updated team");
			} else {
				for (let group in data.errors) {
					data.errors[group].forEach(error => {
						let message = (group !== "__all__" ? group + ": " : "") + error.message;
						app.alert("error", "Failed to update team", message);
					});
				}
			}

			return data;
		});
	}

	getTeams() {
		if (this.teamsState !== "none") return;
		this.teamsState = "loading";

		return this.request("/api/scoreboard").then(data => {
			this.teamsState = "loaded";
			app.setState({
				teams: data
			});
			if (app.state.challs.length) {
				app.sortTeams();
			}

			return data;
		});
	}

	submitFlag(challenge, flag) {
		return this.request("/api/submit_flag", {
			flag: flag,
			challenge_id: challenge
		}).then(data => {
			if (data.success) {
				if (!data.correct) {
					app.alert("error", "Incorrect flag");
					return;
				}

				app.alert("success", "Correct flag");

				// TODO: handle next challenge
			} else {
				for (let group in data.errors) {
					data.errors[group].forEach(error => {
						let message = (group !== "__all__" ? group + ": " : "") + error.message;
						app.alert("error", "Failed to submit flag", message);
					});
				}
			}

			return data;
		});
	}

	rate(challenge, rating) {
		return this.request("/api/rate", {
			challenge_id: challenge,
			rating: rating
		}).then(data => {
			if (data.success) {
				app.alert("success", "Rated challenge");
			} else {
				for (let group in data.errors) {
					data.errors[group].forEach(error => {
						let message = (group !== "__all__" ? group + ": " : "") + error.message;
						app.alert("error", "Failed to rate challenge", message);
					});
				}
			}
		});
	}
}
