/*
Group options:
* By category
* By author
* By status
* None

Sort options:
* By solves
* By rating

Special groups:
* Solved
* Locked
* Series

Behaviour:
* Show all solved
* Show all unsolved
* Show all locked
* Show all from category
* Show all from author
* Show all but categories

*/

/*let colors = ["#ef3737", "#1798ef", "#d72cea", "#f73484", "#8934f7", "#01c3b1", "#e6852f", "#b3845b", "#f1bf05", "#5ba2b3"];
*/

import React from "react";
import { Route } from "react-router-dom";

import history from "./history";

/*const colors = [
	"#ef3737", // red
	"#1798ef", // blue
	"#d72cea", // pink-purple
	"#f73484", // pink
	"#01c3b1", // turquoise
	"#e6852f", // orange
	"#8934f7", // purple
	"#b3845b"  // brown
];*/

const colors = {
	"daydun": "#8934f7",
	"loovjo": "#e6852f",
	"mateuszdrwal": "#01c3b1",
	"nnewram" : "#ef3737",
	"QMechanic": "#1798ef",
	"loke": "radial-gradient(#942b03 , #07ff0f)"
};

class Challenge extends React.Component {
	constructor(props) {
		super(props);
		
		this.click = this.click.bind(this);
	}
	
	click() {
		if (this.props.locked) return;
		
		history.push("/challenges/" + this.props.id);
	}
	
	render() {
		let classes = ["challenge"];
		if (this.props.locked) classes.push("locked");
		if (app.state.team && this.props.id in app.state.team.solves) classes.push("solved");
		
		return (
			<div className={classes.join(" ")} onClick={this.click} style={{background: this.props.color, borderColor: this.props.color}}>
				<span className="title">{this.props.title || "???"}</span>
				<span className="points">{this.props.points}</span>
				<ul className="categories">
					{this.props.categories.map(a => <li key={a}>{a}</li>)}
				</ul>
				<span className="solves">{this.props.solves}</span>
				<i className="icon"></i>
			</div>
		);
	}
}

class Group extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);

		this.state = {
			open: true
		};
	}

	toggle() {
		this.setState({
			open: !this.state.open
		});
	}

	render() {
		return (
			<div className={"group" + (this.state.open ? "" : " closed")}>
				<h2 className="title" onClick={this.toggle}>{this.props.name}</h2>
				<div className="content">
					{this.props.children}
				</div>
			</div>
		);
	}
}

class Rater extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			hover: null
		};
	}
	
	over(rating) {
		this.setState({
			hover: rating
		});
	}
	
	out() {
		this.setState({
			hover: null
		});
	}
	
	rate(rating) {
		if (this.props.onRate) {
			this.props.onRate(rating);
		}
	}
	
	render() {
		let stars = [];
		for (let i=1; i<=5; i++) {
			let active = false;
			if (this.state.hover !== null) {
				active = this.state.hover >= i;
			} else if (this.props.rating !== null) {
				active = this.props.rating >= i;
			}
			
			stars.push(<span
				key={i}
				title={`Rate ${i} star` + (i > 0 ? "s" : "")}
				className={active ? "active" : ""}
				onMouseOver={this.over.bind(this, i)}
				onMouseOut={this.out.bind(this)}
				onClick={this.rate.bind(this, i)}
			></span>);
		}
		
		return <div className="rater">
			{stars}
		</div>;
	}
}

class Rating extends React.Component {
	render() {
		return <div className="rating" title={this.props.title}>
			<div style={{width: this.props.rating / 5 * 100 + "%"}}></div>
		</div>
	}
}

class ChallengeModal extends React.Component {
	constructor(props) {
		super(props);
		
		this.solve = this.solve.bind(this);
		this.close = this.close.bind(this);
		this.rate = this.rate.bind(this);
		
		this.id = parseInt(this.props.match.params.challenge);
		if (this.props.challs[this.id].status !== 0) {
			this.close();
		}
	}
	
	close() {
		history.push("/challenges");
	}
	
	solve(event) {
		event.preventDefault();
		
		let flag = event.target.children.flag.value;
		if (!flag) return false;
		
		app.net.submitFlag(this.id, flag);
		event.target.children.flag.value = "";
		
		return false;
	}
	
	rate(rating) {
		app.net.rate(this.id, rating);
	}

	render() {
		let chall = this.props.challs[this.id];
		
		let solved = app.state.team && this.id in app.state.team.solves;
		
		return <>
			<div className="overlay all" onClick={this.close}></div>
			<div className="challenge-modal">
				<div className={"card" + (solved ? " solved" : "")} style={{background: chall.color}}>
					<span className="title">{chall.title}</span>
					<span className="points">{app.calculateScore(chall)}</span>
					<ul className="categories">
						{chall.categories.map(a => <li key={a}>{a}</li>)}
					</ul>
					<span className="solves">{chall.solves}</span>
					<i className="icon"></i>
				</div>
				<div className="body">
					<div className="info">
						<p dangerouslySetInnerHTML={{__html: chall.description}}></p>
						<div className="prop">
							<span className="title">Authors:</span>
							<ul className="categories">
								{chall.authors.map(author => {
									return <li style={{background: colors[author]}} key={author}>{author}</li>;
								})}
							</ul>
						</div>
						<div className="prop">
							<span className="title">Solves:</span>
							<span>{chall.solves}</span>
						</div>
						{!!chall.netcat_ips.length && <div className="prop">
							<span className="title">Services:</span>
							<ul className="services">
								{chall.netcat_ips.map(service => {
									return <li key={service}>{"nc " + service.replace(/\:/, " ")}</li>;
								})}
							</ul>
						</div>}
						{!!chall.website_urls.length && <div className="prop">
							<span className="title">Websites:</span>
							<ul className="websites">
								{chall.website_urls.map(website => {
									return <li key={website}><a href={website} target="_blank">{website}</a></li>
								})}
							</ul>
						</div>}
						{!!chall.file_urls.length && <div className="prop">
							<span className="title">Files:</span>
							<ul className="files">
								{chall.file_urls.map(file => {
									return <li key={file}><a href={file}>{file.split("/").pop()}</a></li>;
								})}
							</ul>
						</div>}
						<div className="split">
							<div className="prop">
								<span className="title">Solves:</span>
								<table className="solves">
									<tbody>
										{chall.top_solved_teams.map((team, i) => {
											return <tr key={i} title={`Solved at ${new Date(team.time * 1000)}, took ${team.tries} tries`}>
												<td><span className="rank">{i + 1}</span></td>
												<td><span className="team">{team.name}</span></td>
											</tr>;
										})}
									</tbody>
								</table>
							</div>
							<div className="prop">
								<span className="title">Ratings:</span>
								<div className="ratings">
									<label>
										<span>Your team's rating:</span>
										<Rater rating={chall.team_rating} onRate={this.rate} />
									</label>
									<label>
										<span>Solved:</span>
										<Rating
											rating={chall.solved_rating_count === 0 ? 0 : chall.solved_rating_sum / chall.solved_rating_count}
											title={chall.solved_rating_count === 0 ? "No ratings" : `Teams who solved the challenge rated it ${Math.floor(chall.solved_rating_sum / chall.solved_rating_count * 100) / 100} / 5`}
										/>
									</label>
									<label>
										<span>Unsolved:</span>
										<Rating
											rating={chall.unsolved_rating_count === 0 ? 0 : chall.unsolved_rating_sum / chall.unsolved_rating_count}
											title={chall.unsolved_rating_count === 0 ? "No ratings" : `Teams who haven't solved the challenge rated it ${Math.floor(chall.unsolved_rating_sum / chall.unsolved_rating_count * 100) / 100} / 5`}
										/>
									</label>
								</div>
							</div>
						</div>
					</div>
					<form className="submit" onSubmit={this.solve}>
						{Date.now() > app.endTime ? <>
							<span>CTF is over</span>
						</> : (app.state.team ? (solved ? <>
							<span>Already solved</span>
						</> : <>
							<input type="text" name="flag" placeholder="watevr{flag}" />
							<input type="submit" value="Solve" />
						</>) : <>
							<span>Join a team to participate</span>
						</>)}
					</form>
				</div>
			</div>
		</>;
	}
}

class ChallengePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	render() {
		if (this.props.challs.length === 0) {
			return <main>
				<h1>Challenges</h1>
				<div className="loading">
					<span className="title">You're early</span>
					<span className="body">Check back when the competition has started</span>
				</div>
			</main>;
		}
		
		let challs = {};
		this.props.challs.forEach(chall => {
			chall.color = colors[chall.authors[0]];
			challs[chall.id] = chall;
		});
		
		this.props.challs.forEach(chall => {
			if (chall.unlocked_by) {
				challs[chall.unlocked_by].unlocks = chall.id;
			}
		});
		
		let groups = {};
		this.props.challs.forEach(chall => {
			let group = chall.categories[0];
			if (!(group in groups)) groups[group] = {challs: [], chains: []};
			if (chall.unlocks) {
				let chain = [];
				while ("unlocks" in chall) {
					chain.push(chall);
					chall = challs[chall.unlocks];
				}
				chain.push(chall);
				groups[group].chains.push(chain);
			} else if (!chall.unlocked_by) {
				groups[group].challs.push(chall);
			}
		});
		
		return <main>
			<h1>Challenges</h1>
			<ul className="challenges">
				{Object.keys(groups).sort().map(group => {
					return <Group name={group} key={group}>
						{groups[group].challs.map(chall => {
							return <Challenge
								title={chall.title}
								color={chall.color}
								points={app.calculateScore(chall)}
								categories={chall.categories}
								solves={chall.solves}
								id={chall.id}
								locked={chall.status !== 0}
								key={chall.id}
							/>
						})}
						{groups[group].chains.map(chain => {
							return <div className="chain" key={chain[0].id}>
								{chain.map(chall => {
									return <Challenge
										title={chall.title}
										color={chall.color}
										points={app.calculateScore(chall)}
										categories={chall.categories}
										solves={chall.solves}
										id={chall.id}
										locked={chall.status !== 0}
										key={chall.id}
									/>
								})}
							</div>
						})}
					</Group>
				})}
				{/*<Group name="Test">
					<Challenge title="Chall 1" color="#ef3737" points="500" categories={["bob", "misc"]} solves="5" />
					<Challenge title="Chall 2" color="#1798ef" points="500" categories={["bob", "misc"]} solves="5" />
					<Challenge title="Chall 3" color="#d72cea" points="500" categories={["bob", "misc"]} solves="5" />
					<Challenge title="Chall 4" color="#f73484" points="500" categories={["bob", "misc"]} solves="5" />
					<Challenge title="Chall 5" color="#01c3b1" points="500" categories={["bob", "misc"]} solves="5" />
					<Challenge title="Chall 6" color="#e6852f" points="500" categories={["bob", "misc"]} solves="5" />
					<Challenge title="Chall 7" color="#8934f7" points="500" categories={["bob", "misc"]} solves="5" />
					<Challenge title="Chall 8" color="#b3845b" points="500" categories={["bob", "misc"]} solves="5" />
				</Group>*/}
			</ul>
			<Route path="/challenges/:challenge" render={props => <ChallengeModal
				{...props}
				challs={challs}
			/>} />
		</main>;
	}
}

export default ChallengePage;
