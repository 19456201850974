import React from "react";

import history from "./history";

class SignUpPage extends React.Component {
	submit(event) {
		let username = event.target.children.username.value;
		
		app.net.register(username).then(data => {
			if (!data.success) {
				return;
			}
			
			history.push("/profile");
			app.setState({
				user: data.user
			});
		});
		
		event.preventDefault();
		return false;
	}
	
	render() {
		return <main>
			<h1>Sign Up</h1>
			<form className="signup" onSubmit={this.submit}>
				<h2>Choose a username</h2>
				<input type="text" name="username" placeholder="Username" required />
				<input type="submit" value="Submit" />
			</form>
		</main>;
	}
}

export default SignUpPage;
