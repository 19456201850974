/*
Hello dear reader.

I don't like react, it was a mistake choosing it for this project.
But we're stuck with it for now. I'm probably not following best
react practices, but react is enough of a pain already, so deal
with it.
*/

/*
TODO: Add global state to sync clocks
TODO: Get a more consistent color palette
*/

import App from "./app";
import { Provider } from "react-redux";
import { createStore } from "redux";

import React from "react";
import { render } from "react-dom";
import { Router } from "react-router-dom";

import history from "./history";


window.addEventListener("DOMContentLoaded", function() {
	render(
		<Router history={history}>
			<App ref={ref => window.app = ref} />
		</Router>,
		document.getElementById("app")
	);
});
