import React from "react";

import history from "./history";

class SignInPage extends React.Component {
	open(platform) {
		let url = app.net.oauth[platform];
		let win = window.open(url, platform, "width=600,height=800");
		let interval = setInterval(() => {
			if (win.closed) {
				clearInterval(interval);
				return;
			}
			
			let code;
			try {
				if (win.location.pathname !== "/callback/") {
					return;
				}
				code = decodeURIComponent(win.location.search.slice(1).split("&").map(a => a.split("=")).filter(a => a[0] === "code")[0][1]);
			} catch(e) {
				return;
			}
			
			win.close();
			app.net.login(platform, code).then(data => {
				if (data.registration_required) {
					history.push("/signup");
					return;
				} else if (data.success) {
					history.push("/profile");
				}
			});
			clearInterval(interval);
		}, 10);
	}

	render() {
		return <main>
			<h1>Sign In / Sign Up</h1>
			{this.props.user ? (
				<h2>Already signed in</h2>
			) : (
				<div className="signin-buttons">
					<button className="sso discord" onClick={e => this.open("discord")}></button>
					<button className="sso github" onClick={e => this.open("github")}></button>
					<button className="sso google" onClick={e => this.open("google")}></button>
				</div>
			)}
		</main>;
	}
}

export default SignInPage;
