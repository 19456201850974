import React from "react";

class TeamPage extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			id: parseInt(this.props.match.params.id)
		};
	}
	
	render() {
		if (!app.net || app.net.teamsState !== "loaded") {
			if (app.net) {
				app.net.getTeams();
			}
			
			return <main>
				<h1>Team</h1>
				<h2>Loading</h2>
			</main>;
		}
		
		let team = app.state.teams.find(team => team.id === this.state.id);
		let rank = app.state.teams.indexOf(team) + 1;
		
		if (!team) {
			return <main>
				<h1>Team</h1>
				<h2>Team does not exist</h2>
			</main>;
		} else if (!app.startTime) {
			return <main>
				<h1>Team</h1>
				<h2>Loading challenges...</h2>
			</main>;
		}
		
		let timeline = [];
		for (let id in team.solves) {
			let chall = app.state.challs.find(chall => chall.id === parseInt(id));
			let time = new Date(team.solves[id].time * 1000).toString().split(" ");
			time = `${time[1]} ${time[2]} ${time[4]}`;
			
			timeline.push({
				id: id,
				title: chall.title,
				points: app.calculateScore(chall),
				time: time,
				timestamp: team.solves[id].time,
				user: team.solves[id].user,
				tries: team.solves[id].tries
			});
		}
		timeline.sort((a, b) => a.timestamp - b.timestamp);
		
		let members = [];
		for (let i=0; i < team.members.length; i++) {
			let name = team.members[i];
			let score = 0;
			let solves = 0;
			for (let j=0; j < timeline.length; j++) {
				if (timeline[j].user !== name) continue;
				
				solves++;
				score += timeline[j].points;
			}
			
			members.push({
				name: name,
				solves: solves,
				score: score
			});
		}
		
		return <main>
			<h1>{team.name}</h1>
			<div className="team-info">
				<span className="rank" title="Rank">{rank}</span>
				<span className="score" title="Score">{team.score}</span>
				<span className="solves" title="Solves">{timeline.length}</span>
				<span className="members" title="Members">{members.length}</span>
				<br />
				{team.affiliation && <div className="affiliation" title="Affiliation">{team.affiliation}</div>}
				<br />
				<a className="website" href={team.website}>{team.website}</a>
			</div>
			<h2>Members</h2>
			<ul className="team-members">
				{members.map(member => {
					return <li key={member.name}>
						<span className="title">{member.name}</span>
						<div className="body">
							<span className="solves" title="Solves">{member.solves}</span>
							<span className="score" title="Score">{member.score}</span>
						</div>
					</li>;
				})}
			</ul>
			<h2>Solves</h2>
			<ul className="team-timeline">
				{timeline.map(solve => {
					return <li key={solve.id}>
						<div>
							<span className="title">{solve.title}</span>
							<div className="body">
								<span className="user" title="User">{solve.user}</span>
								<span className="points" title="Points">{solve.points}</span>
								<span className="time" title="Time">{solve.time}</span>
								<span className="tries" title="Tries">{solve.tries}</span>
							</div>
						</div>
					</li>;
				})}
			</ul>
		</main>;
	}
}

export default TeamPage;
