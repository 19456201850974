import React from "react";
import { NavLink } from "react-router-dom";

class NavButton extends React.Component {
	render() {
		return (
			<NavLink className="nav-button" exact={this.props.exact} to={this.props.to} activeClassName="active">
				<span>{this.props.children}</span>
			</NavLink>
		);
	}
}

class NavClock extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			time: Date.now(),
			start: app.startTime,
			end: app.endTime
		};
	}

	componentDidMount() {
		this.interval = setInterval(() => this.tick(), 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	tick() {
		this.setState({
			time: Date.now(),
			start: app.startTime,
			end: app.endTime
		});
	}

	render() {
		const now = this.state.time;
		const start = this.state.start;
		const end = this.state.end;

		let seconds, minutes, hours, days;

		function zeroPad(value, length) {
			return String(value).padStart(length, "0");
		}

		function setTime(time) {
			time = Math.floor(time / 1000);
			seconds = zeroPad(time % 60, 2);

			time = Math.floor(time / 60);
			minutes = zeroPad(time % 60, 2);

			time = Math.floor(time / 60);
			hours = zeroPad(time % 24, 2);

			time = Math.floor(time / 24);
			days = zeroPad(time, 2);
		}

		if (now < start) {
			setTime(start - now);
		} else if (now < end) {
			setTime(end - now);
		} else {
			seconds = "--";
			minutes = "--";
			hours = "--";
			days = "--";
		}

		return (
			<div className="nav-clock">{days}:{hours}:{minutes}:{seconds}</div>
		);
	}
}

class NotifMenu extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="notif-menu">
				<h2>Notifications</h2>
				<ul>
					{app.state.notifs.map((notif, i) => {
						let timestamp = new Date(notif.time * 1000).toString().split(" ").slice(1, 5).join(" ").slice(0, -3);
						return <li key={i}>
							<span className="title">{notif.title}</span>
							<span className="desc">{notif.description}</span>
							<span className="timestamp">{timestamp}</span>
						</li>;
					}).reverse()}
				</ul>
			</div>
		);
	}
}

class NotifButton extends React.Component {
	constructor(props) {
		super(props);

		this.open = this.open.bind(this);
		this.close = this.close.bind(this);

		this.state = {
			isOpen: false
		};
	}

	open() {
		this.setState({
			isOpen: true
		});
		localStorage["wctf:notifs-read"] = app.state.notifs.length;
	}

	close() {
		this.setState({
			isOpen: false
		});
	}

	render() {
		let unread;
		if (app.state) {
			unread = app.state.notifs.length - parseInt(localStorage["wctf:notifs-read"] || 0);
		} else {
			unread = 0;
		}

		return (
			<div className="notif">
				<div className="notif-button-container" onClick={this.open}>
					<div className={"notif-button" + (unread > 0 ? " unread" : "") + (this.state.isOpen ? " active" : "")}>
						{unread > 0 && <span>{unread}</span>}
						<i className="material-icons">notifications</i>
					</div>
				</div>
				{this.state.isOpen && <div className="overlay" onClick={this.close}></div>}
				{this.state.isOpen && <NotifMenu />}
			</div>
		);
	}
}

class NavBar extends React.Component {
	render() {
		let connected = app.state && app.state.connected;

		return <nav>
			<div className="container">
				<ul className="left">
					<NavButton to="/" exact>Home</NavButton>
					<NavButton to="/challenges">Challenges</NavButton>
					<NavButton to="/scoreboard" exact>Scoreboard</NavButton>
				</ul>
				<ul className="right">
					<NavClock />
					<NotifButton />
				</ul>
			</div>
		</nav>;
	}
}

export default NavBar;
