import React from "react";

class Page404 extends React.Component {
	render() {
		return <main>
			<h1>404</h1>
		</main>;
	}
}

export default Page404;
